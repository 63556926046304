.userinfo {

    .grid {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .grid-flow-col {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .grid-rows-2 {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }
}