.settings {
    .ant-picker-body {
        @media (max-width: 768px) {
            padding: 5px !important;
        }
    }

    .ant-picker-date-panel {
        @media (max-width: 768px) {
            width: 100% !important;
        }
    }

    .ant-picker-dropdown {
        @media (max-width: 768px) {
            inset: 50px auto auto 1px !important;
        }
    }
}

.ant-picker-body {
    @media (max-width: 768px) {
        padding: 5px !important;
    }
}

.ant-picker-date-panel {
    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.ant-picker-dropdown {
    @media (max-width: 768px) {
        inset: 50px auto auto 1px !important;
    }
}