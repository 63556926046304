@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Dark Scrollbar */
    .dark-scrollbar::-webkit-scrollbar {
      background-color: #f5f5f5;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      color: #37364f;
      width: 12px;
      height: 12px;
    }
    .dark-scrollbar::-webkit-scrollbar-thumb {
      background: #37364f;
      border-radius: 16px;
      box-shadow: inset 3px 3px 3px #37364f, inset -2px -2px 2px #37364f;
    }
    .dark-scrollbar::-webkit-scrollbar-track {
      background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
    }

    /* Light Scrollbar */
    .light-scrollbar::-webkit-scrollbar {
      background-color: #ff7ff00;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      color: #d62929;
      width: 12px;
      height: 12px;
    }
    .light-scrollbar::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 16px;
      box-shadow: inset 3px 3px 3px #f0f0f0, inset -2px -2px 2px #f0f0f0;
    }
    .light-scrollbar::-webkit-scrollbar-track {
      background: linear-gradient(
        90deg,
        #e1e7ed,
        #e1e7ed 1px,
        #e1e7ed 0,
        #e1e7ed
      );
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt;
  Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
