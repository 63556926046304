.home {

    .ant-col {
        height: 50%;
    }

    svg {
        height: 70px;
    }

    .ant-table-tbody>tr>td {
        padding: 0px 16px !important;
    }

    .h-96 {
        @media (max-width: 768px) {
            height: 5rem !important;
        }
    }

    .grid {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .grid-flow-col {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .grid-rows-2 {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .h-96 {
        @media (max-width: 768px) {
            height: 5rem !important;

        }
    }

    .home-wrap {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    &-wrap {
        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }
}

.h-96 {
    @media (max-width: 768px) {
        height: 5rem !important;
    }
}

.home-wrap {
    @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
    }
}

.grid {
    @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
    }
}

.ant-picker-body {
    @media (max-width: 768px) {
        padding: 5px !important;
    }
}

.ant-picker-date-panel {
    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.ant-picker-dropdown {
    @media (max-width: 768px) {
        inset: 50px auto auto 1px !important;
    }
}